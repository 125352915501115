
import { defineComponent } from 'vue'
import { routGo } from '@/hooks/links'
export default defineComponent({
  name: 'AIGC',
  props: {
    txt: {
      type: String,
      default: '开启AI创新之旅'
    },
    content: {
      type: String,
      default: '体验易用和性能的融合'
    },
    isAigc: {
      type: Boolean,
      default: true
    },
    changeAigcStatus: {
      type: Function,
      default: () => console.log('1111')
    }
  },
  setup(props){
    // console.log(props.isAigc)
    return { routGo }
  }
})
