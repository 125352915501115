
import { defineComponent, onMounted } from 'vue'
import linkGo, { routGo } from '../hooks/links'
import SecondFooter from '@/components/SecondFooter.vue'
export default defineComponent({
  name: 'Unite',
  components: {
    SecondFooter,
  },
  setup () {
    // const aigcmodelVis = ref<boolean>(false);
    // const changeAigcStatus = (bool: boolean): void => {
    //   aigcmodelVis.value = bool
    //   if(bool){
    //     document.body.style.overflowY = 'hidden'
    //   }else{
    //     document.body.style.overflowY = 'scroll'
    //   }
    // }
    onMounted(() => {
      _hmt.push(['_trackPageview', '/unite'])
    })
    return { routGo , linkGo }
  }
})
